export const usePhoneVerifyStore = defineStore("phoneVerifyStore", () => {
  const verifyWindowIsRequired = ref(false);

  function showVerifyWindow() {
    document.documentElement.style.overflow = "hidden";
    verifyWindowIsRequired.value = true;
  }

  function hideVerifyWindow() {
    verifyWindowIsRequired.value = false;
    document.documentElement.style.overflow = "";
  }

  return {
    verifyWindowIsRequired,
    showVerifyWindow,
    hideVerifyWindow,
  };
});
